import React from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container } from "react-bootstrap"
import SEO from "../components/seo"

const GeneralConditions = () => {
  return (
    <Layout>
      <SEO title="Conditions générales d’utilisation" />
      <Section className="pt-5 pb-2" css={{ marginTop: "60px" }}>
        <Container className="content mt-3">
          <h1 className="mb-0">Conditions générales d’utilisation</h1>
        </Container>
      </Section>
      <Section className="pt-0 content">
        <Container>
          <p>
            L’espace personnel proposé par Simulassur.fr est soumis aux
            conditions générales d’utilisation ci-dessous.
          </p>
          <p>
            L’accès et l’utilisation du site Simulassur.fr valent acceptation
            sans réserve des conditions générales du service. L’entreprise se
            réserve le droit de modification, à son entière discrétion, de ses
            conditions générales d’utilisation.
          </p>

          <h2 className="pt-3">Utilisation du service</h2>
          <p>Simulassur.fr vous permet de :</p>
          <ul>
            <li>Mettre à jour et compléter vos informations personnelles</li>
            <li>Répondre au questionnaire de santé le cas échéant</li>
            <li>Signer votre demande d’adhésion</li>
            <li>Récupérer vos documents</li>
            <li>Communiquer avec notre service de gestion</li>
            <li>Résilier votre contrat</li>
          </ul>

          <h2 className="pt-3">Droit de rétractation</h2>
          <p>
            Dans le cas d’une souscription d’une assurance à distance
            (téléphone, internet), vous disposez d’un délai de 14 jours de
            rétractation pour votre assurance. En effet, l’article L.112-9 du
            Code des Assurances vous permet d’exercer ce droit, sans motif ni
            pénalité. Nous vous invitons également à consulter la Notice
            d’Information valant Conditions Générales de l’assureur choisi pour
            plus d’informations.
          </p>

          <h2 className="pt-3">Exhaustivité des informations</h2>
          <p>
            Simulassur.fr ne peut pas affirmer ou garantir les informations sur
            son site internet, notamment au niveau des tarifs. Les informations
            sur le site nous sont communiquées par les partenaires et n’y sont
            données qu’à titre informatif, elles ne constituent pas une offre ou
            une vente de services. En cas d’erreur, seul le site du partenaire
            choisi peut faire foi.
          </p>

          <h2 className="pt-3">Responsabilité</h2>
          <p>
            Simulassur.fr décline toute responsabilité dans les cas suivants :
          </p>
          <ul>
            <li>
              Interruption, suspension ou indisponibilité de tout ou partie des
              services du Site dans des circonstances indépendantes de notre
              volonté (dans ce cas, nous ferons tout notre possible pour vous
              informer de l’indisponibilité de ces derniers et ferons le maximum
              pour les rétablir au plus vite)
            </li>
            <li>
              Seule une étude de votre dossier nous permettra de vous proposer
              une offre personnalisée, en fonction de votre situation et
              conformément aux lois régissant les activités d’intermédiaire
              d’assurance, et sous réserve d’acceptation de votre dossier par un
              de nos partenaires assureurs. Nous ne pouvons donc en aucun cas
              garantir que les propositions de nos partenaires après étude
              personnalisée de votre dossier seront conformes aux résultats de
              la simulation.
            </li>
            <li>
              Tout dommage résultant de votre propre violation des présentes CGU
              ou de notre politique de données personnelles
            </li>
            <li>
              Tout dommage résultant de l’utilisation des sites web auxquels
              nous renvoyons à partir de ce Site et qui ne sont pas édités par
              ELOIS.
            </li>
          </ul>
          <p>
            En tout état de cause, vous reconnaissez être seul responsable de
            l’utilisation faite du Site et des conséquences de cette
            utilisation.
          </p>
          <p>Vous vous engagez également à :</p>
          <ul>
            <li>
              Maintenir strictement confidentiels vos identifiants et mot de
              passe et à ne les communiquer à aucun tiers pour lui permettre
              l’accès à l’ensemble des Services du Site
            </li>
            <li>
              Répondre très exactement aux questions qui vous sont posées car,
              en cas d’omission ou fausse déclaration, les obligations à
              indemnisation de nos Partenaires assureurs pourraient être
              réduites et votre contrat annulé, conformément aux articles L113-8
              et L113-9 du Code des Assurances.
            </li>
          </ul>

          <h2 className="pt-3">Réclamations & Médiation</h2>
          <p>
            Vous pouvez consulter notre politique de traitement des
            réclamations, ainsi que les informations relatives à la médiation
            sur notre page dédiée :
            <a
              href="https://www.simulassur.fr/reclamation/"
              className="pl-2"
              style={{ textDecoration: "underline" }}
            >
              https://www.simulassur.fr/reclamation/
            </a>
          </p>

          <h2 className="pt-3">Usage commercial</h2>
          <p>
            Le site Simulassur.fr ne peut pas être utilisé dans un but
            commercial et est réservé pour un usage personnel uniquement. Nous
            nous réservons le droit d’interdire l’accès aux personnes utilisant
            le service de manière non autorisée.
          </p>

          <h2 className="pt-3">Loi applicable</h2>
          <p>
            Le site Simulassur.fr est régi par la loi française, peu importe le
            lieu de connexion ainsi que le lieu de domicile de l’utilisateur.
          </p>

          <h2 className="pt-3">Disponibilité du site</h2>
          <p>
            Simulassur.fr est disponible 24 heures sur 24, 7/7j afin de proposer
            un service continu. Nous ne pouvons cependant pas garantir une
            disponibilité immédiate, notamment en cas de force majeure, ou dans
            le cas de mises à jour, interventions, maintenances.
          </p>
          <p>
            De ce fait, nous pouvons être amenés à interrompre le site. Notre
            responsabilité ne pourrait être engagée en cas d’interruption du
            service Simulassur.fr.
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default GeneralConditions
